@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Roboto', sans-serif;
}

body{
    background-color: black;
    color: white;
}