.parent {
    background-color: black;
    height: 3rem;
    font-size: 22px;
    padding-left: 1.25rem;
    display: block;
    width: 100%;
    color: rgb(156 163 175 / var(--tw-text-opacity));
    border-radius: 1.5rem;
    border-width: 1px;
    border-color: #ebf411;
    margin-top: 20px;
}

.parent:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.container {
    background-color: black !important;
    color: white !important;
    width: 80% !important;
    border: 0 !important;
    height: 3rem;
    font-size: 16px !important;
    padding-top: 10px !important;
}

@media (min-width: 426px) {
    .container {
        font-size: 22px !important;
    }
}

@media (max-width: 426px) {
    .container {
        width: 90% !important;
    }
}

.dropdown-button {
    background-color: black !important;
    border: 0 !important;
}

.dropdown-class {
    background-color: black !important;
    color: rgb(255, 255, 255);
}

@media only screen and (min-width: 320px) {
    .dropdown-class {
        width: 220px !important;
    }
}

@media only screen and (min-width: 425px) {
    .dropdown-class {
        width: 250px !important;
    }
}

@media only screen and (min-width: 768px) {
    .dropdown-class {
        width: 450px !important;
    }
}


.dropdown-class li:hover {
    background-color: rgb(53, 53, 53) !important;
}

.highlight {
    background-color: rgb(53, 53, 53) !important;
}

.open {
    background-color: rgb(53, 53, 53) !important;
}

.drop-container {
    max-width: 100% !important;
    min-width: 100% !important;
    height: 120px !important;
    border: dashed 2px white !important;
    z-index: 100;
}

.drop-container input {
    color: white !important;
}

.drop_zone div span {
    color: white !important;
    font: 1em sans-serif;
    visibility: hidden;
}
.drop_zone svg{
    visibility: hidden;
}

.drop-container svg {
    color: white !important;
}

.selected-flag {
    width: 50px !important;
}

.arrow {
    color: white;
}

.selector {
    margin-top: 1.25rem;
}

.selector div div {
    font-size: 22px;
    padding-left: 8px;
}


.selector div div div {
    color: white;
    background-color: black;
}

.selector div div {
    border-radius: 1.5rem !important;
    background-color: black;
    border-color: rgb(235 244 17);
    box-shadow: none;
}

.arrow {
    visibility: hidden;
}

@media only screen and (max-width: 425px) {
    .file-types {
        visibility: hidden;
    }
}